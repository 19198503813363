@import 'account-widget/_account-widget';
@import 'vendorswitcher/_vendorswitcher';

.app-bar {
  width: 100%;
  background: $background;
  padding: 20px 20px 20px 30px;
  @include flexbox(0 0 auto);

  .notification-section {
    position: fixed;
    width: 25%;
    max-height: 70%;
    height: 70%;
    overflow-y: scroll;
    scrollbar-width: thin;
    align-items: stretch;
    z-index: 1000000;
    bottom: 15px;
    right: 15px;
    border-radius: 7px;
    background-color: #eaeaea;
    box-shadow: 0px 0px 23px -2px rgba(0, 0, 0, 0.37);
    border: 2px solid #fff;
    padding-bottom: 15px;

    &.mobile {
      width: 93%;

      .inner-notification-clear-icon {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        line-height: 40px;
      }

    }

    .outer-notification-title-body-wrapper {
      padding: 0px 15px;
    }

    .notification-title-body-wrapper {
      position: relative;
    }

    .notification-header {
      font-family: "Duplicate Slab";
      padding: 25px 20px;
      font-size: 17px;
      background-color: white;
      
    }

    .notification-title {
      font-family: myriad-pro, sans-serif;
      font-size: 17px;
      font-weight: 200;
      margin: 10px 0px 20px 0px; 
      color: $syscodarkblue;
    }

    .notification-body {
      font-family: myriad-pro, sans-serif;
      font-size: 15px;
      font-weight: 200;
      line-height: 22px;
      color: #707070;
    }

}

.inner-notification-clear-icon {
    position: absolute;
    margin-top: -9px;
    right: 9px;
    background-color: #d74f4f;
    border: none;
    cursor: pointer;
    align-content: end !important;
    color: white;
    font-weight: bolder;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    text-align: center;
    line-height: 22px;
    font-family: myriad-pro,sans-serif;
    border: 1px solid #d74f4f;
    font-weight: 100;

    &:hover {
      background-color: #f77f7f;
    }
}

  .inner-notification-section {
      color: #6a737b;
      background-color: #fff;
      width: 98%;
      text-align: left;
      margin: 30px 5px;
      padding: 15px 25px;
      border-radius: 4px;
      box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.37);
      font-size: 14px;
  }
}

.branding {
  @include flexbox(0 0 auto, row);
  .logo {
    height: 44px;
    @include breakpoint(xs) {
      z-index: $bringToTop;
      position: absolute;
      top: 0.8rem;
      left: 2rem;
    }
    cursor: pointer;
  }
  .app-logo-wrapper {
    border-left: 1px dotted $bluegrey;
    font-size: 24px;
    padding-left: 17px;
    font-weight: 100;
    height: 29px;
    margin-left: 17px
  }
  .app-logo {
    height: 37px;
    margin-left: 1rem;
    @include breakpoint(xs) {
      z-index: $bringToTop;
      position: absolute;
      top: 1.5rem;
    }
  }
}

// App-wise custom menu items.
.nav {
  @include flexbox(0 0 auto, row, center, center);
  @include bg-border(right);
  margin-right: 0.6rem;
  padding: 0.8rem 1rem;
  position: absolute;
  top: 1rem;
  right: 26.5rem;
  li {
    cursor: pointer;
    transition: color ease-in-out 300ms, border ease-in-out 300ms;
    // transition: all ease-in-out 300ms;
    padding: 0.2rem 0.8rem;
    margin: 0 0.2rem;
    text-transform: uppercase;
    font-weight: 600;
    border: 1px solid white;
    border-radius: 1rem;
    font-size: 0.9rem;
    outline: none;
    .icon {
      margin-right: 0.15rem;
    }
    &:hover,
    &:focus {
      color: $syscoblue;
      border: 1px solid $syscoblue;
      &.selected {
        box-shadow: 0px 0px 2px 3px rgba(104, 177, 217, 0.4);
      }
    }
    &.selected,
    &:active {
      // color: $syscoblue;
      // border-bottom: 3px solid $syscoblue;
      transition-duration: 150ms;
      border-radius: 1rem;
      color: white;
      background: $syscoblue;
      border: 1px solid $syscoblue;
      box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.32);
    }
  }
  @include breakpoint(xs) {
    display: none;
  }
  @include breakpoint(sm) {
    display: none;
  }
}

//Inner App mode switches are below
.app-bar.app-mode {
  .app-logo-wrapper,
  .app-menu-drawer {
    display: flex;
  }
  .organisation {
    background: $background;
    color: $syscodarkblue;
    border: 1px solid #d2dbe2;
    .type {
      border-right: 1px dotted $syscodarkblue;
      opacity: 0.6;
      margin-top: -1px;
    }
    .name {
      opacity: 0.6;
      margin-top: -1px;
    }
  }
}

.mobile-header {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    img {
      width: 35px;
    }

    .brand-label {
      font-weight: 100;
      font-size: 13px;
    }
}
